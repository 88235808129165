<template>
    <div class="full-width">
        <div class="subpage-wrapper-small">
            <div class="text-xs-center subpage-content">
                <h1 class="sz-kath-title">
                    {{lang.ContactInfo.ContactInfoTitle}}
                </h1>
                <p>
                    {{lang.ContactInfo.ContactInfoP1}}
                </p>
                <p>
                    {{lang.ContactInfo.ContactInfoP2}}
                </p>
            </div>
            <div class="mb-4 subpage-content">
                <Sekretariat></Sekretariat>
            </div>
        </div>
        <div>
            <Kirchenvorstand></Kirchenvorstand>
        </div>
    </div>
</template>

<script>
    import Kirchenvorstand from "../components/KirchenVostand.vue";
    import Sekretariat from "./Sekretariat.vue";
    import DesktopSubmenuItem from "../components/navigation/DesktopSubmenuItem";
    export default {
        name: "Contact",
        data () {
            return {
                langKeys: [
                    'ContactInfo',
                ]
            }
        },
        components: {
            DesktopSubmenuItem,
            Sekretariat,
            Kirchenvorstand
        },
        computed: {
            lang () {
                return this.$store.getters.textsForKeysInArray(this.langKeys)
            }
        },
        beforeCreate() {
            this.$store.dispatch('setViewSizeToNormal');
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }
    }
</script>

<style scoped>

</style>